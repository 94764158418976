#tableOddsVertical th {
    padding: 0.5rem;
}

#tableOddsVertical tr {
    td {
        padding: 0.5rem;
    }

    td:nth-child(1) {
        background: #58c4ff;
    }

    td:nth-child(2) {
        background: #A9A9A9;
        animation: StrikeChange 0.5s ease;
    }

    td:nth-child(3) {
        background: #ff92bd;
    }

    &.dummy-row td:nth-child(2) {
        animation: none;
    }
}

@keyframes StrikeChange {
    0% {
        background-color: #4b4a4a;
    }

    100% {
        background: #A9A9A9
    }
}